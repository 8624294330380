@import "../../style.scss";

.share {
  @include themify($themes) {
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    margin-bottom: 20px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;

    @include mobile {
      width: 100%;
    }

    .container {
      padding: 20px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          flex: 5;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            margin: 1rem;
          }

          textarea {
            border: none;
            outline: none;
            padding: 20px 10px;
            background-color: transparent;
            width: 100%;
            color: themed("textColor");
            resize: none;
            overflow: hidden;
            font-weight: 600;
          }

          .suggestions-dropdown {
            position: absolute;
            background-color: #F8FAFC;
            border: 1px solid #9AA6B2;
            max-height: 150px;
            overflow-y: auto;
            width: 35%;
            z-index: 100;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
            top: 200px;
            margin-left: 60px;
          
            .suggestion-item {
              padding: 10px;
              cursor: pointer;
              color: #000;
          
              &:hover {
                background-color: #D9EAFD;
              }
            }
          }                  
        }
        .right {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          .file {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 0px;
          }
        }
      }

      .inputError{
        color: rgb(241, 86, 86);
        font-size: small;
      }

      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          gap: 20px;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            img {
              height: 20px;
            }

            span {
              font-weight: 600;
              font-size: 12px;
              color: gray;
            }
          }
        }
        .right {
          
          button {
            @include button-primary;
            color: themed("bg");
            display: flex;
            justify-content: center;
            gap: 10px;
            font-weight: 700;
            padding: 10px;
            background-color : themed("primary-color");
            // background-color : themed("button-primary-bg");

            .loader{
              display: flex;
              justify-content: center;
              align-items: center;
              p{
                color: themed("bg");
                font-weight: 700;
                font-size: medium;
                margin-right: 5px;
              }
            }  
  
            .spinner{
              width: 1rem;
              height: 1rem;
              border: 2px solid themed("bg");
              border-radius: 50%;
              border-top: none;
              border-right: none;
              animation: spin 0.4s linear infinite ;
            }
          }

          button:hover {
            background-color: themed("primary-color-hover");
          }
        }
      }
    }
  }
}
