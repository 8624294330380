.auto-carousel {
    width: 100%;
    max-width: 800px;  
    margin: 0 auto;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-image {
    // width: 100%;
    height: 400px;
    max-width: 95%;
    object-fit: cover; 
    transition: opacity 0.5s ease-in-out;  
    border-radius: 10px;
  }
  

