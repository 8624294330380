@import "../../style.scss";

.eventsPage {
  @include themify($themes) {
    width: 100%;
    height: 100vh;
    border: none;
    background-color: themed('bg');
    color: themed('textColor');
    padding: 20px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }

    .events {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      place-items: center;
      margin-top: 20px;

      .event {
        width: 300px;
        height: 450px !important;
        box-shadow: 0px 0px 5px lightgray;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        background-color: themed('bgSecondary');
        .image {
          flex: 1;
          position: relative;
          .video-container, .thumbnail-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          }
          video, .thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
          }
          .spinner-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;


            text-align: center;
            // background: rgba(0,0,0,0.05);
            border-radius: 4px;
            margin-bottom: 20px;
            padding: 30px 50px;
            margin: 20px 0;

            
          }
          .custom-spinner .ant-spin-lg .ant-spin-dot-item {
            //.ant-spin-lg .ant-spin-dot-item
            //.ant-spin-lg .ant-spin-dot-holder
            width: 60px !important;
            height: 60px !important;
            font-size: 50px;
          }
          .video-controls {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            gap: 10px;
            // background: rgba(0, 0, 0, 0.5);
            padding: 5px;
            border-radius: 5px;
            .play-pause, .mute-unmute {
              background: none;
              color: white;
              border: none;
              font-size: 16px;
              cursor: pointer;
              padding: 5px;
              &:hover {
                color: yellow;
              }
            }
          }
          .play-icon {

            position: absolute;
  
            top: 50%;
  
            left: 50%;
  
            transform: translate(-50%, -50%);
  
            width: 70px;
  
            height: 70px;
  
            z-index: 10; 
  
            cursor: pointer;
  
          }
        }
        
      }
    }
  }
}
